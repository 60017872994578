import * as React from 'react';
import { useState, useRef } from 'react';
// import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/EditCalendar';
import DoneIcon from '@mui/icons-material/Done';
import CameraIcon from '@mui/icons-material/CenterFocusStrong';
import AccountIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import QuestionIcon from '@mui/icons-material/HelpOutlineRounded';
import DownloadForOfflineRounded from '@mui/icons-material/DownloadForOfflineRounded';
import ReportIcon from '@mui/icons-material/ReportProblemOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import { Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import axios from 'axios';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import html2canvas from 'html2canvas';
import { isChrome } from 'react-device-detect';


// ----------------------------------------------------------------------------------
// ---------- Settings go here ------------------------------------------------------
// ----------------------------------------------------------------------------------
// Styling from MUI UI
const CustomColor = withStyles({
  root: {
    fontSize: 20,
    background: "-webkit-linear-gradient(45deg, #3f50b5 30%, #f44336 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
})(Typography);

// main color: #9C27B0
const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledButton = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

// Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBHJcXwn-I3CEgR-JEzJGr01BVcu0ADoIU",
  authDomain: "aivi-63ce1.firebaseapp.com",
  databaseURL: "https://aivi-63ce1.firebaseio.com",
  projectId: "aivi-63ce1",
  storageBucket: "aivi-63ce1.firebasestorage.app",
  messagingSenderId: "106223559889",
  appId: "1:106223559889:web:4572c8ffaf4c5db449d1f0"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Latex config to display Math
const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  },
  options: {
    menuOptions: {
      settings: {
        assistiveMml: false,
      },
    },
  },
};
// ----------------------------------------------------------------------------------
// ---------- End settings ----------------------------------------------------------
// ----------------------------------------------------------------------------------



export default function BottomAppBar() {

  // // ----------------------------------------------------------------------------------
  // // ---------- Refresh page after 1 hour idle. Not work??? ---------------------------
  // // ----------------------------------------------------------------------------------
  // const isScreenFocused = useRef(true); // Ref to track if the app is focused (on screen)
  // const timeoutId = useRef(null); // Ref to hold the timeout ID
  // useEffect(() => {
  //   const handleFocus = () => {
  //     isScreenFocused.current = true;
  //     // Clear any existing timeout when the app comes back into focus
  //     if (timeoutId.current) {
  //       clearTimeout(timeoutId.current);
  //       timeoutId.current = null;  // Reset the timeout ID
  //     }
  //   };

  //   const handleBlur = () => {
  //     isScreenFocused.current = false;
  //       // Only start the timeout if the app is no longer focused
  //     if (!timeoutId.current) {
  //       startTimeout();
  //     }
  //   };

  //   const startTimeout = () => {
  //     const timeoutDuration = 60 * 60 * 1000; // 1 hour in ms
  //     timeoutId.current = setTimeout(() => {
  //       if (!isScreenFocused.current) { // Double check before reloading
  //         window.location.reload();
  //       }
  //       timeoutId.current = null; // Clear after reload
  //     }, timeoutDuration);
  //   };

  //   window.addEventListener('focus', handleFocus);
  //   window.addEventListener('blur', handleBlur);

  //   // Set initial timeout if the app starts in the background
  //   if (!document.hasFocus()) {
  //     startTimeout();
  //   }

  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //     window.removeEventListener('blur', handleBlur);
  //     if (timeoutId.current) {
  //       clearTimeout(timeoutId.current);
  //     }
  //   };
  // }, []);
  // // ---------- End refreshing --------------------------------------------------------


  // ----------------------------------------------------------------------------------
  // ---------- Basic functions: api call, download solution... go here ---------------
  // ----------------------------------------------------------------------------------
  let maxLength = 4 * 100000;
  // handle api call
  let apiUrl = 'https://h2c-server-167533398884.asia-southeast1.run.app/';
  const handleApiCall = async (apiUrl, dataStr) => {
    if(dataStr.length >= maxLength) {
      logEvent(analytics, 'large_input_error', { name: 'large_input_error'});
      return '*Khung ảnh quá lớn hoặc quá nhiều bài tập trong khung ảnh. Vui lòng chọn từng bài tập để có chất lượng bài giải tốt nhất.*';
    }
    let response = '';
    try{
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(() => setLag(true), 8000); // 8 seconds to show lag signal
      });

      const result = await axios.post(apiUrl, dataStr,
        {
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      );
      await Promise.race([timeoutPromise, result]);
      response = result.data;

    } catch (error) {
      if(error.status === 429) {
        logEvent(analytics, 'reach_quota_limit', { name: 'reach_quota_limit'});
        setQuotaError(true);
      }
      else setNetWorkError(true);
    }
    return response;
  }

  // Handle feedback
  const reportFeedback = (feedback, inputType, inputData, response) => {
    logEvent(analytics, 'feedback: ' + feedback, { name: 'report_feedback'});
    const jsonObject = {};
    jsonObject["feedback"] = feedback;
    jsonObject["inputType"] = inputType;
    jsonObject["input"] = inputData;
    jsonObject["response"] = response;
    handleApiCall(apiUrl + 'feedback', JSON.stringify(jsonObject));
    if(feedback === 'bad') setReportError(true);
  }

  // download solution
  const today = new Date();
  const webpageRef = useRef(null);
  const printDocument = async () => {
    logEvent(analytics, 'download_solution', { name: 'download_solution'});
    try {
      const canvas = await html2canvas(webpageRef.current, {scale: 2});
      const dataURL = canvas.toDataURL('image/png'); // Specify PNG format
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = '[h2c.com.vn]_bài-giải-' + today.getFullYear() + '.' + (today.getMonth()+1) + '.' + today.getDate() + '_' + today.getTime() + '.png'; // Set filename
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(dataURL);
        if (!isChrome) window.location.href = link.href;
      }, 5000);
    } catch (error) {
      alert("Lỗi tải file. Vui lòng kiểm tra đường truyền mạng.");
    }
    
  };

  // rotate accidentally vertical image 
  const rotateBase64Image = async (base64, degrees) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate dimensions after rotation
        const radians = degrees * Math.PI / 180;
        const rotatedWidth = Math.abs(img.width * Math.cos(radians)) + Math.abs(img.height * Math.sin(radians));
        const rotatedHeight = Math.abs(img.width * Math.sin(radians)) + Math.abs(img.height * Math.cos(radians));

        canvas.width = rotatedWidth;
        canvas.height = rotatedHeight;

        ctx.translate(canvas.width / 2, canvas.height / 2); // Center the rotation point
        ctx.rotate(radians);
        ctx.drawImage(img, -img.width / 2, -img.height / 2); // Draw the image centered

        const newBase64 = canvas.toDataURL();
        resolve(newBase64);
      };
      img.onerror = reject;
      img.src = base64;
    });
}
  // ---------- End basic functions ---------------------------------------------------

  

  // ----------------------------------------------------------------------------------
  // ---------- Load & crop image -----------------------------------------------------
  // ----------------------------------------------------------------------------------
  const [imageURI, setImageURI] = useState(''); // this is for upload image from device
  const inputFile = useRef(null) ;
  const [isVertical, setIsVertical] = useState(); // this is to decide how to fit the image into screen
  // handle cropping
  let defaultCrop = {unit: 'px',width: window.innerWidth * 0.8,height: window.innerHeight * 0.3,x: window.innerWidth * 0.1,y: window.innerHeight * 0.35}
  const [crop, setCrop] = useState(window.innerHeight/window.innerWidth);
  const [initCropFromUpload, setInitCropFromUpload] = useState(true);
  const [finalImage, setFinalImage] = useState(''); // this is the final task image after cropping

  // This component is to handle crop view
  const CropImg = () => {
    const onLoad = async (e) => {
      // console.log(window.innerHeight + ", " + window.innerWidth + ", " + e.target.naturalHeight + ", " + e.target.naturalWidth);
      if(initCropFromUpload) {
        let threshold = 100;
        if(window.innerHeight > e.target.naturalHeight && window.innerWidth > e.target.naturalWidth) {
          if(e.target.naturalHeight / e.target.naturalWidth > window.innerHeight / window.innerWidth)
            setIsVertical(true);
          else setIsVertical(false);
          defaultCrop.width = e.target.naturalWidth * 0.8;
          defaultCrop.x = e.target.naturalWidth * 0.1; 
          defaultCrop.height = e.target.naturalHeight * 0.3;
          if(defaultCrop.height < threshold) defaultCrop.height = e.target.naturalHeight < threshold ? e.target.naturalHeight : threshold;
          defaultCrop.y = (e.target.naturalHeight - defaultCrop.height) / 2;
        }
        else {
          if(e.target.naturalHeight / e.target.naturalWidth > window.innerHeight / window.innerWidth) {
            setIsVertical(true);
            let scaleWidth = e.target.naturalWidth * window.innerHeight / e.target.naturalHeight;
            defaultCrop.width = scaleWidth * 0.8;
            defaultCrop.x = scaleWidth * 0.1; 
          }
          else {
            setIsVertical(false);
            let scaleHeight = e.target.naturalHeight * window.innerWidth / e.target.naturalWidth;
            defaultCrop.height = scaleHeight * 0.3;
            if(defaultCrop.height < threshold) defaultCrop.height = scaleHeight < threshold ? scaleHeight : threshold;
            defaultCrop.y = (scaleHeight - defaultCrop.height) / 2;
          }
        }
        setCrop(defaultCrop);
        setInitCropFromUpload(false);
      }
      // if(e.target.naturalHeight / e.target.naturalWidth > window.innerHeight / window.innerWidth) setIsVertical(true);
    }
    return <img id='crop-image' src={imageURI} alt='crop-image' onLoad={onLoad} sx={{justifyContent:'center'}} style={{maxWidth: window.innerWidth, maxHeight: window.innerHeight, justifyContent: 'center', alignContent: 'center'}}/>
  }
 
  // This component is to handle upload file
  const ImageInput = () => {
    
    const onChange = async e => {
      if (e.target.files && e.target.files.length > 0) {
        setImageURI(URL.createObjectURL(e.target.files[0]));
      }
      // console.log(defaultCrop);
      setCrop(defaultCrop);
      setInitCropFromUpload(true);
    }
    return <input ref={inputFile} type='file' onChange={onChange} style={{display: 'none'}}/>
  }

  // This function is to extract & perform the task including rest api call
  const cropPhoto = async () => {
    logEvent(analytics, 'handle_task_from_image', { name: 'handle_task_from_image'});
    setLoading(true);
    setLag(false);
    
   // Crop view
    const canvas = document.createElement('canvas');
    let image = document.getElementById('crop-image');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,    
    );

    // Get answer
    const base64Image = canvas.toDataURL('image/jpeg');
    const i = base64Image.indexOf('base64,');
    const base64data = base64Image.substring(i + 7);  
    const response = await handleApiCall(apiUrl + 'image', base64data);

    
    setAnswer(response);
    // setReport(false);
    setReportError(false);
    // setFinalImage(base64Image);
    crop.height / crop.width > 2.5 ?  rotateBase64Image(base64Image, 270, document).then(rotated => setFinalImage(rotated)) : setFinalImage(base64Image);
    setImageURI('');
    setLoading(false);  
  }
  // ---------- End processing image --------------------------------------------------
  


  // ----------------------------------------------------------------------------------
  // ---------- Handle text task ------------------------------------------------------
  // ----------------------------------------------------------------------------------
  const [inputFromKeyboard, setInputFromKeyBoard] = useState(); // this is to know if the input is keyboard or image
  const [recentTaskFromKeyboard, setRecentTaskFromKeyboard] = useState(''); // last input, thus user can edit it later

  // This is to perform the task from keyboard
  const performTextTask = async () => {
    logEvent(analytics, 'handle_task_from_keyboard', { name: 'handle_task_from_keyboard'});
    let taskDescription = document.getElementById('input-task-text-field').value;
    if(taskDescription !== '') {
      setLoading(true);
      setLag(false);
      const response = await handleApiCall(apiUrl + 'text', taskDescription);

      
      setAnswer(response);
      // setReport(false);
      setReportError(false);
      setRecentTaskFromKeyboard(taskDescription);
      setInputFromKeyBoard(false);
      setFinalImage('');
      setLoading(false);
    } 
    else {
      backMainScreen();
    } 
  }
  // ---------- End processing text task ----------------------------------------------

  
  
  const [loading, setLoading] = useState(); // this is to know when loading annimation is displayed
  const [lag, setLag] = useState(); // this is to know if the user is waiting long, should show a status message
  const [answer, setAnswer] = useState(''); // this is to store the answer
  const [networkError, setNetWorkError] = useState(); 
  const [quotaError, setQuotaError] = useState();
  const [reportError, setReportError] = useState(); // user click: wrong solution 
  
  // reset all states
  const reset = () =>  {
    setImageURI('');
    setFinalImage('');
    setAnswer('');
    setInputFromKeyBoard(false);
    setLag(false);
    setLoading(false);
  }
  
  // Back to main screen
  const backMainScreen = () => {
    logEvent(analytics, 'back_to_main_screen', { name: 'back_to_main_screen'});
    window.location.reload();
    reset();
  }



  return (

    <React.Fragment >
      <CssBaseline />
      <Box sx={{ width: '100%', height: '100%', position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent:'center',
          flexDirection: 'column',
      }} >
        {
          !inputFromKeyboard && answer ==='' && !networkError && !quotaError &&
          // <CustomColor style={{fontFamily: 'sans-serif', fontSize:32}}>H2C: Giải bài nhờ AI</CustomColor>
          <div>
            <CustomColor style={{fontFamily: 'sans-serif', fontSize:32}}>H2C: Giải bài nhờ AI</CustomColor>
            <div style={{height: 100}}></div>
          </div>
        }
        {
          !inputFromKeyboard && networkError && 
          <Alert sx={{borderRadius: 2}} style={{fontFamily: 'sans-serif'}} severity="warning" onClose={() => {setNetWorkError(false)}}>
              Vui lòng kiểm tra đường truyền mạng và thử lại.
          </Alert>
        }
        {
          !inputFromKeyboard && quotaError && 
          <Alert sx={{borderRadius: 2}} style={{fontFamily: 'sans-serif'}} severity="warning" onClose={() => {setQuotaError(false)}}>
              Quá nhiều truy vấn cùng lúc. Vui lòng thử lại sau.
          </Alert>
        }
      </Box>

      <Container sx={{overflowY: 'hidden'}} ref={webpageRef}>

        {/* This dummy code is for space only */}
        <div style={{height: 10}}></div> 
        
        {/* Heading goes here... */}
        {
          !inputFromKeyboard && !networkError && !quotaError && 
          <Paper id='main-paper' square sx={{pb: '0px', borderRadius: 2, border: 0.5, borderColor: '#9C27B0'}} elevation={5}>
            {
              answer === '' && !networkError && !quotaError &&
              <Alert severity="info" sx={{borderRadius: 2}}>
                <AlertTitle style={{fontFamily: 'sans-serif', fontWeight: 'bold', height: '18px'}}>H2C: Giải bài tập</AlertTitle>
                <p style={{fontFamily: 'sans-serif', fontStyle: 'oblique', height: '8px'}}>1. Chụp hoặc tải ảnh bài tập từ thiết bị.</p>
                <p style={{fontFamily: 'sans-serif', fontStyle: 'oblique', height: '8px'}}>2. Tự nhập đề bài khi ảnh không nhận.</p>
                <p style={{fontFamily: 'sans-serif', fontStyle: 'oblique', height: '8px'}}>3. Có ngay bài giải trong 10 giây.</p>
              </Alert>
            }
            
            {
              answer !== ''  && 
              <Typography variant="h6" gutterBottom component="div" sx={{ p: 1, pb: 0 }} style={{fontFamily: 'sans-serif', fontStyle: 'italic'}}>
                Đề bài:
              </Typography>
            }
            {
              answer !== '' && finalImage &&
              <img style={{maxWidth: window.innerWidth < 1200 ? window.innerWidth * 0.9 : window.innerWidth * 0.75, margin: 'auto', display: 'block', borderRadius: 10, padding: 5}} src={finalImage} alt='task'/>
            }
            {
              answer !== '' && !finalImage &&
              <Typography variant="h7" gutterBottom component="div" sx={{ p: 1, pb: 2}} style={{fontFamily: 'sans-serif', fontStyle: 'italic'}}>
                "{recentTaskFromKeyboard}"
              </Typography>
            }
          </Paper>
        }         

        {
          inputFromKeyboard &&
          <Modal open={inputFromKeyboard} style={{backdropFilter: "blur(8px)", justifyContent: 'center',alignContent:'center'}}>
            <div style={{height: window.innerHeight, backgroundColor: 'white'}}>
              <div style={{height: 20}}></div>
              <TextField
                sx={{width: window.innerWidth * 0.9, left: window.innerWidth * 0.05}}
                style={{fontFamily: 'sans-serif'}}
                color="secondary"
                // fullWidth
                id="input-task-text-field"
                label="Nhập đề bài"
                multiline
                // maxRows={10}
                variant="standard"
                defaultValue={recentTaskFromKeyboard}
                onDragEnter={performTextTask}
              />
              
              <StyledButton color="secondary" aria-label="upload" onClick={performTextTask}>
                <CloudUploadIcon />
              </StyledButton>
              <IconButton color="inherit" aria-label="done" sx={{position: 'absolute', top:10, right:10}} onClick={performTextTask}>
                <DoneIcon />
              </IconButton>
            </div>
          </Modal>
        }

        
        <Modal open={imageURI !== '' && isVertical} style={{backdropFilter: "blur(8px)", justifyContent: 'center'}}>
          <Box sx={{ width: '100%', position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'top',
            flexDirection: 'column',
          }} >
            <ReactCrop crop={crop} onChange={c => {setCrop(c)}} keepSelection='true'>
              <CropImg/>
            </ReactCrop>
            <StyledButton color="secondary" aria-label="upload" onClick={cropPhoto}>
              <CloudUploadIcon />
            </StyledButton>
            <IconButton color="inherit" aria-label="close" sx={{position: 'absolute', top:10, right:10}} onClick={backMainScreen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal> 

       
        <Modal open={imageURI !== '' && !isVertical} style={{backdropFilter: "blur(8px)", alignContent:'center'}}>
          <Box sx={{ width: '100%', position: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }} >
            <ReactCrop crop={crop} onChange={c => {setCrop(c)}} keepSelection='true'>
              <CropImg/>
            </ReactCrop>
            <StyledButton color="secondary" aria-label="upload" onClick={cropPhoto}>
              <CloudUploadIcon />
            </StyledButton>
            <IconButton color="inherit" aria-label="close" sx={{position: 'absolute', top:10, right:10}} onClick={backMainScreen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal>  

        

        {/* Loading */}
        <Modal open={loading} style={{backdropFilter: "blur(8px)", alignContent:'center'}}>
          <Box sx={{ width: '100%', position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }} >
            <LinearProgress color="secondary" sx={{width: '100%'}}/>
            {
              loading && lag && <p sx={{justifyContent:'center'}} style={{fontFamily: 'sans-serif', color:'#FFF4E5', fontStyle:'italic'}}>Đang giải bài! Vui lòng đợi trong giây lát...</p>
            }
          </Box>
        </Modal>  

       
        {/* Show answer */}
        {
          answer && answer.indexOf("Xin lỗi") === -1 &&
          <div style={{height: 30}}>
              <p style={{fontSize: '20px'}}>&#128073; <em><span style={{color: '#777777'}}>Bài giải từ</span> <span style={{color: '#9C27B0'}}>h2c.com.vn</span></em></p>
          </div>
        }
        {
          answer &&
          <MathJaxContext config={config}>
            <div style={{fontFamily: 'sans-serif'}}>
              <MathJax dynamic hideUntilTypeset="every">
                <Markdown rehypePlugins={[rehypeRaw]} >
                  {String(answer)}
                </Markdown>
              </MathJax>
              <div style={{height: 10}}></div>

              {
                reportError ?
                <Button sx={{borderRadius: 5, textTransform: 'inherit'}} style={{fontFamily: 'sans-serif'}} startIcon={<ReportIcon />} variant="outlined" color="error" size="small" disabled>
                  Ghi nhận
                </Button>
                :
                <Button sx={{borderRadius: 5, textTransform: 'inherit'}} style={{fontFamily: 'sans-serif'}} startIcon={<ReportIcon />} variant="outlined" color="error" size="small" onClick={() => reportFeedback("bad", finalImage ? "image":"text", finalImage? finalImage:recentTaskFromKeyboard, answer)}>
                  Báo sai
                </Button>
              }
              &nbsp;
              <Button sx={{borderRadius: 5, textTransform: 'inherit'}} style={{fontFamily: 'sans-serif'}} startIcon={<QuestionIcon />} variant="outlined" color="warning" size="small" onClick={() => {window.open('https://www.facebook.com/groups/1147515353449116/', '_blank')}}>
                Hỏi cộng đồng
              </Button>
              &nbsp;
              <Button sx={{borderRadius: 5, textTransform: 'inherit'}} style={{fontFamily: 'sans-serif'}} startIcon={<DownloadForOfflineRounded />} variant="outlined" color="info" size="small" onClick={() => {setTimeout(() => {printDocument()}, 1000);}}>
                Lưu bài giải
              </Button>              
              
              

              {/* <div style={{height: 30}}></div> */}
              <p style={{color: '#555555', fontSize: '14px'}}>&#128293;<em>Chia sẻ cho bạn bè để nhiều người được trợ giúp.</em></p>
              
              <div style={{height: 100}}></div>
            </div>
          </MathJaxContext>
        } 

      
          

        {/* Bottom Bar */}
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="account" disabled>
              <AccountIcon />
            </IconButton>
            <StyledFab color="secondary" aria-label="add" onClick={() => {logEvent(analytics, 'click_camera_button', { name: 'click_camera_button'}); inputFile.current.click();}}>
              <CameraIcon />
            </StyledFab>


            <Box sx={{ flexGrow: 1 , paddingTop: 3}} style = {{textAlign: 'center'}}>
            {
              answer === '' && !networkError && !quotaError &&
                <div style={{fontFamily: 'sans-serif', fontStyle: 'oblique', fontSize: '20px'}}>Bấm để bắt đầu</div>
            }
            </Box>
            
            
            <IconButton color="inherit" aria-label="edit" onClick={() => setInputFromKeyBoard(true)}>
              <EditIcon />
            </IconButton>
          </Toolbar>

          
        </AppBar>

        
        
        <ImageInput/> {/* This is hidden input for uploading photo */}
      </Container>    
    </React.Fragment>
    
  );
}

